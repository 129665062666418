import React from 'react';
import ReactDOM from 'react-dom';


import App from './components/App';
document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('root')
  if (element)
    {
      const {reviews, jobDetails, mainResult} = element.dataset
      ReactDOM.render(
        <React.StrictMode>
          <App reviews={JSON.parse(reviews)}  jobDetails={JSON.parse(jobDetails)} initialResult={JSON.parse(mainResult)}   />
        </React.StrictMode>,element
      );
    }
});